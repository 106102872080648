
.icon-container i.fa.icon-circle {
    font-size: 1rem;
}

.icon-container i.fa.icon-circle.fa-trash {
    background-color: red;
    color: white;
}

.icon-container i.fa.icon-circle.fa-eye {
    background-color: #6F74EF;
}

.icon-container i.fa.icon-circle:not(:first-child) {
    margin-left: 0.1rem;
}

.file-entry-margin {
    padding-top: 0.5em;
}

.file-entry-margin span {
    user-select: none;
}

.file-entry-drag:hover {
    cursor: ns-resize;
}


.project-view-item.last-item {
    margin-bottom: 0.5rem;
}

.project-view-item .header h4 {
    border-bottom: 1px solid black;
}

.project-view-item i.fa-pencil-alt {
    font-size: 1rem;
    background-color: #673796;
}

.project-view-item .header .icon-container i:not(:first-child) {
    margin-left: 0.1rem;
}

.experiment.button-override .empty-button:hover {
    background-color: var(--primary);
    color: white;
}

.bottom-right-icons i.save {
    background-color: #217051;
    box-shadow: 0 0 2px #217051;
}

.bottom-right-icons i.cancel {
    margin-top: .5rem;
    background-color: red;
    box-shadow: 0 0 2px red;
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.autocomplete-menu {
    border-radius: 3px;
    box-shadow: 0px 6px 10px 0px rgba(0,0,0,0.75);
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0;
    font-size: 90%;
    position: absolute;
    overflow: auto;
    z-index: 99;
    padding: 0.25rem;
    max-height: 100px;
}


.experiment .empty-button {
    background-color: white;
    color: black;
}

.experiment .close-icon {
    color: red;
    top: -0.2rem;
    right: -0.3rem;
    font-size: 1.5rem;
}

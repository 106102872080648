nav {
  z-index: 999;
}

nav .navbar-collapse .nav-item a,
nav .navbar-collapse .nav-item button.anchor {
  color: black;
  font-weight: bold;
}

nav .navbar-collapse.collapse .nav-item:hover a,
nav .navbar-collapse.collapse .nav-item:hover button.anchor,
nav .navbar-collapse.collapse .nav-item.active a,
nav .navbar-collapse.collapse .nav-item.active button.anchor,
nav .navbar-collapse.collapsing .nav-item:hover a,
nav .navbar-collapse.collapsing .nav-item:hover button.anchor,
nav .navbar-collapse.collapsing .nav-item.active a,
nav .navbar-collapse.collapsing .nav-item.active button.anchor {
  color: var(--secondary-background);
}

nav .navbar-collapse .list-item .dropdown-menu {
  min-width: 100%;
  margin: 0.06rem 0 0;
}

nav .navbar-collapse .list-item .dropdown-menu a.dropdown-item,
nav .navbar-collapse .list-item .dropdown-menu button.anchor.dropdown-item {
  color: var(--dark-card-background);
}

nav .navbar-collapse .list-item .dropdown-menu a.dropdown-item:hover,
nav
  .navbar-collapse
  .list-item
  .dropdown-menu
  button.anchor.dropdown-item:hover,
nav .navbar-collapse .list-item .dropdown-menu a.dropdown-item.active,
nav
  .navbar-collapse
  .list-item
  .dropdown-menu
  button.anchor.dropdown-item.active {
  color: var(--secondary-background);
  background-color: initial;
}

@media (min-width: 992px) {
  nav .nav-link.active {
    box-shadow: 0px 8px 0px 0px var(--secondary-background-box-color);
  }

  nav .navbar-collapse.collapse .nav-item a,
  nav .navbar-collapse.collapse .nav-item button.anchor {
    background-color: white;
    transition: height 0.1s linear;
  }
}

li.nav-item {
  white-space: nowrap;
}

@media (max-width: 992px) {
  nav .navbar-collapse.show .list-item:not(.nav-group-header) a,
  nav
    .navbar-collapse.collapsing
    .list-item:not(.nav-group-header)
    button.anchor {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  nav .navbar-collapse.show .list-item:not(.nav-group-header),
  nav .navbar-collapse.show .nav-item:not(.nav-group-header),
  nav .navbar-collapse.collapsing .list-item:not(.nav-group-header) {
    height: 3rem;
    min-height: 48px;
  }

  nav .navbar-collapse.show .list-item:not(.nav-group-header),
  nav .navbar-collapse.show .nav-item:not(.nav-group-header),
  nav .navbar-collapse.collapsing .list-item:not(.nav-group-header) {
    padding-bottom: 0.5rem;
    transition: padding-bottom 0.1s linear, border-bottom 0.1s linear;
  }

  nav .navbar-collapse.show .list-item.active:not(.nav-group-header),
  nav .navbar-collapse.show .list-item:hover:not(.nav-group-header),
  nav .navbar-collapse.collapsing .list-item.active:not(.nav-group-header),
  nav .navbar-collapse.collapsing .list-item:hover:not(.nav-group-header) {
    padding-bottom: 0;
    border-bottom: 0.5rem solid var(--secondary-background-box-color);
  }

  nav .navbar-collapse.show .list-item.dropdown.hover:hover .dropdown-menu,
  nav
    .navbar-collapse.collapsing
    .list-item.dropdown.hover:hover
    .dropdown-menu {
    display: none;
  }

  .nav-group > .nav-item:not(:last-child),
  .nav-item:not(.nav-group-header) {
    border-bottom: 1px solid lightgrey;
  }
}

nav .navbar-collapse.show .list-item.nav-group-header,
nav .navbar-collapse.collapsing .list-item.nav-group-header {
  height: initial;
}

.nav-group {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

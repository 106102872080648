
.footer-fix-to-bottom {
    position: absolute;
    width: 100%;

    margin: 0;
    bottom: 0;
    left: 0;
}

.footer .image-container .divider {
    border-top: 1px solid #999999;
}

.footer .image-container img {
    max-width: 100%;
    max-height: 100%;
}

.footer .image-container img.fixed-height {
    height: 60px;
}

.overlay {
    z-index: 1000;

    top: 0;
    left: 0;
    position: fixed;

    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.overlay > .content {
    position: fixed;
    width: 90vw;
    height: 90vh;
    top: 5vh;
    left: 5vw;

    overflow-y: auto;
    overflow-x: auto;

    background-color: white;
}

.overlay > .content > .close-icon {
    position: fixed;
    top: 5vh;
    right: calc(5vw + 1rem);
}

.overlay > .content > .close-icon > i {
    color: red;
    font-size: 1.5rem;
}



.filter-icon,
.filter-panel {
    margin-top: 15px;
    position: fixed;
    transition: left .5s ease-in-out, width .5s ease-in-out;
    box-shadow: 3px 0 5px 0 rgba(0,0,0,0.75);
    background-color: #F6FBFF;
}

.filter-icon {
    left: -6px;
    z-index: 151;
    border-radius: 0 60px 60px 0;
}

.filter-icon > i {
    color: black;
    font-size: 1.5rem;
    display: inline-block;
    border-radius: 0 60px 60px 0;
    padding: 0.5em 0.6em;

    border: 1px solid #F6FBFF;
    border-left: 0;
}

.filter-icon.expanded {
    left: calc(45vw - 1px);
    top: calc(71px + 0.75rem);
}

.filter-panel {
    left: calc(-45vw - 6px);
    width: 45vw;
    height: calc(100vh - 86px - 0.75rem);

    z-index: 150;
    box-shadow: 3px 0 5px 0 rgba(0, 0, 0, 0.75);
}

.filter-panel.expanded {
    left: 0;
    border-left: 0;
}

.filter-panel .form-group {
    border: 1px dotted black;
    padding: .1rem;
    margin-bottom: 0.1rem;
}

.filter-panel .icon-remove {
    position: absolute;
    color: white;
    z-index: 4;

    right: .25rem;
    padding: 1px 5px;
}

.filter-panel .btn-primary,
.filter-panel .btn-primary:not(:disabled):not(.disabled):active {
    color: black;
    background-color: var(--secondary-background-box-color);
    border-color: var(--secondary-background-box-color);
}

.filter-panel .btn-primary:not(:disabled):not(.disabled):focus,
.filter-panel .btn-primary:not(:disabled):not(.disabled):active,
.filter-panel .btn-primary:not(:disabled):not(.disabled):focus:active {
    box-shadow: 0 0 0 0.2rem var(--dark-card-background);
}

@media (max-width: 767.98px) {
    .filter-icon.expanded {
        left: calc(75vw - 1px);
    }

    .filter-panel.expanded {
        width: 75vw;
    }
}

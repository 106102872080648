
.create-analysis .max-scrollable-component {
    max-height: 15rem;
    min-height: 15rem;
    overflow-y: auto;
    border: 1px solid rgba(0,0,0,.125);
    overflow-x: hidden;
}

.create-analysis .max-scrollable-component li {
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.create-analysis .max-scrollable-component li:first-child,
.create-analysis .max-scrollable-component li:first-child.active {
    border-top: 0;
}

.create-analysis .max-scrollable-component li:last-child,
.create-analysis .max-scrollable-component li:last-child.active {
    border-bottom: 0;
}

.create-analysis .max-scrollable-component li.active {
    border-top: 1px solid white;
}

.create-analysis i.fa-trash {
    font-size: 0.75rem;
    background-color: red;
}

@media (min-width: 768px) {
    .create-analysis.background-item.collapsed-md-left {
        box-shadow: -1.5px 0px 5px 0px rgba(50, 50, 50, 0.75);
    }

    .create-analysis.background-item.collapsed-md-right {
        box-shadow: 1.5px 0px 5px 0px rgba(50, 50, 50, 0.75);
    }
}

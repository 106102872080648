

.analysis-view .error-container {
    max-height: 25rem;
    max-width: calc(100% - 0.5rem);
    overflow: auto;
    border: 1px solid black;
}

.analysis-view .download-button {
    color: black;
}

.analysis-view .image-preview {
    max-width: 100%;
}

.analysis-view .image-preview:hover {
    outline: var(--secondary-background-box-color) auto 5px;
}

.analysis-view .download-button:hover {
    text-decoration: none;
}

.analysis-view .download-button .container {
    border-radius: 5px;
    border: 1px solid var(--dark-card-background);
    transition: background-color .1s linear;
}

.analysis-view .download-button .container:hover {
    background-color: var(--dark-card-background);
}

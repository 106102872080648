
.parent-overlap {
    left: 0;
    overflow: hidden;
}

.parent-overlap > .overlap {
    transform: rotate(30deg);
    width: 160%;
    height: 80%;
    margin-left: 20%;
}


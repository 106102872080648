
.togglebutton-wrapper {
    font-size: 0;
}

.togglebutton-wrapper.disabled > .choice,
.togglebutton-wrapper.disabled > .choice:first-child,
.togglebutton-wrapper.disabled > .choice:last-child {
    border: 0;
}

.togglebutton-wrapper > .choice {
    display: inline-block;
    font-size: 1rem;
    border-radius: 10px;
    background-color: var(--light);
}

.togglebutton-wrapper.disabled > .choice {
    background-color: grey;
}

.togglebutton-wrapper > .choice.active {
    background-color: var(--primary);
    color: white;
}

.togglebutton-wrapper > .choice:not(.active) {
    background-color: var(--primary-background);
    color: black;
}

.togglebutton-wrapper.disabled > .choice.active {
    color: black;
    background-color: #8EB6E3;
}

.togglebutton-wrapper.disabled > .choice:not(.active) {
    background-color: #DBE0E4;
}

.togglebutton-wrapper > .choice:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.togglebutton-wrapper > .choice:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.togglebutton-wrapper > .choice:not(:first-child):not(:last-child) {
    border-radius: 0;
}

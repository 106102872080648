
.card-view, .card-view > .inner-wrapper {
    height: 200px;
    max-height: 200px;
}

.card-view {
    height: 200px;
    border-radius: 10px;

    box-shadow: 0 2px 8px 0 rgba(50, 50, 50, 0.75);
}

.card-view > .inner-wrapper {
    display: flex;
    flex-direction: column;
}

.card-view > .inner-wrapper > .card-view-header {
    border-bottom: 1px solid black;
}

.card-view > .inner-wrapper > .card-view-header .hamburger {
    padding: 0.5rem;
    font-size: 1.25rem;
    border-radius: 5px;

    right: .5rem;
    top: 2rem;
    transform: translateY(-50%);
}

.card-view > .inner-wrapper > .card-view-header .hamburger:hover,
.card-view > .inner-wrapper > .card-view-header .hamburger.active {
    background-color: var(--dark-card-background);
    cursor: pointer;
}

.card-view > .inner-wrapper > .card-view-header .hamburger-dropdown {
    display: none;
}

.card-view > .inner-wrapper > .card-view-header .hamburger-dropdown > .dropdown-menu {
    min-width: 187px;
    overflow-x: hidden;
    box-shadow: -1px 0 9px 0 rgba(50, 50, 50, 0.75);
}

.card-view > .inner-wrapper > .card-view-header .hamburger-dropdown > .dropdown-menu > .dropdown-item:active,
.card-view > .inner-wrapper > .card-view-header .hamburger-dropdown > .dropdown-menu > .dropdown-item:hover {
    background-color: var(--secondary-background-box-color);
    color: black;
}

.card-view > .inner-wrapper > .card-view-header .hamburger-dropdown > .dropdown-menu > .dropdown-item:focus {
    outline: var(--secondary-background-box-color) auto 5px;
}

.card-view > .inner-wrapper > .card-view-header .hamburger-dropdown.show {
    display: block;
    right: 13rem;
    top: calc(1.25rem - 2px);
    z-index: 3;
    background-color: white;
}

.item-container {
    overflow-y: auto;
}

.hover-checkbox {
    position: absolute;
}

.hover-checkbox {
    left: -2rem;
    padding: 0.5rem 0.2rem 0.5rem 1rem;
    border-radius: 35px 0 0 35px;
    box-shadow: -3px 0 4px -1px rgba(50, 50, 50, 0.75);
}

.hover-checkbox > label {
    margin: 0;
}
